<template>
	<div>
		<div class="btn-invite">
			<invite-user :project-id="projectId" @invite-user:success="handleInviteUserSuccess" />
		</div>
		<b-row class="mt-3">
			<b-col>
				<invitation-list
					:project-id="projectId"
					:user-requests="userRequests"
					@invitation-list:success="handleInvitationListSuccess"
					@invitation-list:delete="handleInvitationListDelete"
				/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import InviteUser from '@/modules/onboarding/components/InviteUser';
import InvitationList from '@/modules/onboarding/components/InvitationList';
import { getUserRequest, delUserRequest } from '@/cruds/users.crud';
import { getProject } from '@/cruds/project.crud';
import { store } from '@/store';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'Invitations',

	mixins: [globalMixin],

	components: {
		InviteUser,
		InvitationList
	},

	data() {
		return {
			projectId: 0,
			project: null,
			userRequests: []
		};
	},

	async created() {
		this.projectId = parseInt(store.state.myProject.id, 10);
		await this.reloadData();
	},

	methods: {
		async handleInviteUserSuccess() {
			await this.reloadData();
		},
		async handleInvitationListSuccess() {
			await this.reloadData();
		},
		async handleInvitationListDelete(payload) {
			try {
				const userRequestId = parseInt(payload.id, 10);
				await delUserRequest(userRequestId);
				this.userRequests = this.userRequests.filter((uR) => parseInt(uR.id, 10) !== userRequestId);

				this.createToastForMobile(this.FormMSG(53, 'Success'), this.FormMSG(54, 'Invitation deleted successfully'), '', 'success');
			} catch (error) {
				this.emitError(error);
			}
		},
		async reloadData() {
			this.userRequests = [];
			try {
				const projNumber = parseInt(store.state.myProject.id);
				this.project = await getProject(projNumber);

				this.userRequests = await getUserRequest({ projectId: projNumber });
			} catch (error) {
				this.emitError(error);
			}
		},
		emitError(error) {
			this.$emit('invitations:error', error);
		}
	}
};
</script>
<style lang="scss">
.btn-invite {
	position: absolute;
	display: inline-flex;
	justify-content: flex-end;
	right: 32px;
	z-index: 10;
}
</style>
