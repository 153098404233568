<template>
	<div class="w-100">
		<b-button variant="success" @click="inviteNewMember" class="px-5">
			{{ FormMSG(1, 'Invite new crew member') }}
		</b-button>
		<b-modal
			v-model="showUserModal"
			header-class="header-class-modal-doc-package"
			hide-header-close
			:title="FormMSG(11, 'Invite user')"
			size="xl"
			centered
			no-close-on-backdrop
			no-close-on-esc
			cancel-variant="light"
		>
			<user-form
				ref="invite-member"
				:display-add-btn="false"
				:projectId="projectId"
				:edit-data="null"
				v-on:closeForm="handleCloseForm"
				@user-form:success="handleUserFormSuccess"
				@user-form:watingAddUpdProject="handleWatingAddUpdProject"
			/>
			<template #modal-footer>
				<div class="w-100 d-flex justify-content-end align-items-center">
					<b-col sm="4" md="4" lg="2" xl="2">
						<b-button size="md" variant="light" @click="okAfterUserEdit" block>
							{{ FormMSG(351, 'Close') }}
						</b-button>
					</b-col>
					<b-col sm="8" md="8" lg="3" xl="3">
						<b-button size="md" variant="primary" :disabled="watingAddProject" @click="dispatchEvent" block>
							<div class="d-flex justify-content-center align-items-center">
								<b-spinner v-show="watingAddProject" small />
								<div class="pl-2" style="margin-top: 1px">{{ FormMSG(2, 'Invite crew member') }}</div>
							</div>
						</b-button>
					</b-col>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import UserForm from '@/views/users/UserForm';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'InviteUser',
	mixins: [languageMessages],
	props: {
		projectId: {
			type: [Number, String],
			required: true
		}
	},
	components: {
		UserForm
	},
	data() {
		return {
			showUserModal: false,
			watingAddProject: false
		};
	},
	methods: {
		handleUserFormSuccess() {
			this.showUserModal = false;
			this.$emit('invite-user:success');
		},
		handleCloseForm() {
			this.toggleUserModal();
		},
		toggleUserModal() {
			this.showUserModal = !this.showUserModal;
		},
		okAfterUserEdit() {
			this.handleCloseForm();
			this.$emit('invite-user:ok-after-user-edit');
		},
		inviteNewMember() {
			this.showUserModal = true;
		},
		dispatchEvent(e) {
			e.preventDefault();
			this.$refs['invite-member'].$refs['submit'].click();
		},
		handleWatingAddUpdProject(payload) {
			this.watingAddProject = payload;
		}
	}
};
</script>
